.slider {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    height: fit-content;
    width: 100%;
}

.video-wrapper {
    height: 5rem;
    position: relative;
    width: 100%;
    border-radius: .6rem;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.lite-youtube-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    max-width: 100%;
    border-radius: .6rem;
}

.lite-youtube-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
} 

.lite-youtube-btn {
    display: block;
    width: 68px;
    height: 48px;
    position: absolute;
    cursor: pointer;
    transform: translate3d(-50%, -50%, 0);
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
    filter: grayscale(100%);
    transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
    border: none;
}


.lyt-activated .lite-youtube-btn {
    display: none;
    pointer-events: none;
}

.portfolio-pic-container{
    width: 100%;
    margin-top: 3px;
    height: 100%;
    border-radius: .6rem;
    box-shadow: 3px 3px 7px #b5b5b5;
}

.shadow {
    border-radius: .6rem;
    box-shadow: 3px 3px 10px #b5b5b5;
}

.portfolio-pic-container img{
    width: 100%;
    border-radius: .6rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.btm-container {
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}



.full-screen-image {
    position: absolute;
    width: 100%;
    z-index: 999;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.slide {
    width: 100%;
    margin-top: 2%;
    cursor: pointer;
    height: 100%;
    padding: 0px;
}

.main-img {
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: .6rem;
    object-fit: cover;
}

.mobile-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    border-radius: .6rem;
    object-fit: cover;
}

.shadow img {
    height: 100%;
}

.active-anim {
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .slide {
        /* width: 100%;
        background-color: aqua;
        */
    }
    .main-img {
        /* width: 100%; */
        
    }

    .mobile-img{
        width: 100%;
    }
}