.projects-container {
    height: auto;
    width: 100vw;
    padding: 1.5rem;
    margin-top: -0.1%;
    background-color: hsl(24, 8%, 88%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.project-item {
    width: 100%;
    height: auto; 
    margin-bottom: 15vh;
}

@media screen and (min-width: 992px) {
    .projects-container{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 1rem 4.37rem;
    }

    .project-item {
        width: 100%;
        height: auto; 
        margin-left: 6rem;
        margin-right: 6rem;
    }
}
