.about-container {
    height: auto;
    width: 100vw;
    background-color: #e3e0de;
    padding: 20vh 1.5rem;
    text-transform: uppercase;
    margin-top: -0.1%;
}

.text-about {
    margin: 0;
    line-height: 1.25;
    font-size: 1.8rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

@media screen and (min-width: 992px) {
    .about-container {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
        justify-content: space-between;
        padding: 20vh 4.37rem;
    }
    .text-about {
        width: 50%;
        padding-right: 6rem;
    }
}

