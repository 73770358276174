.project-container {
    font-family: 'Montserrat', sans-serif;
}

.project-description {
    font-weight: 400;
    padding-bottom: 2rem;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
    position: relative;
    z-index: 3;
}

.project-name {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.project-name h3{
    font-weight: 400;
    font-size: 2.2rem;
    text-transform: uppercase;
    text-decoration: underline;
    width: 54%;
}

.title-side {
    position: relative;
    z-index: 3;
    margin-bottom: 2rem;
}

.highlight-font {
    font-family: 'Inconsolata', monospace;
    font-weight: 500;
    text-transform: uppercase;
}

.highlight-purple {
    color: #8276d1;
}

.links {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    width: 45%;
}

.links div{
    font-family: 'Inconsolata', monospace;
    font-size: 1.25rem;
    text-decoration: none; 
    text-align: center;
    border: 2px solid;
    border-radius: .6rem;
    margin-left: .5rem;
    padding: .3rem;
}

.links a {
    cursor: pointer;
    text-decoration: none;
}

.modal {
    position: fixed; 
    z-index: 100;
    left: 0;
    top: 100%;
    width: 100vw; 
    height: 100vh; 
    background-color: rgba(240, 240, 240, 0.8);
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.modal-content {
    position: relative;
    z-index: 110;
    margin: auto;
    display: block;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (max-width: 390px) {
    .project-name h3{
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 768px) {
    .extra-img-container {
        height: 15vh;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .extra-img-container div {
        width: 49%;
        height: 15vh;
    }
    
    .mobile-screen {
        height: 100%;
    }

    .mobile-screen img{
        border-radius: .6rem;
    }

    .project-container{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    .links {
        justify-content: flex-end;
    }

    .title-side {
        width: 45%;
        height: 100%;
    }

    .description-side {
        width: 50%;
        height: auto;
    }

    .project-name h3{
        font-weight: 400;
        font-size: 3.3rem;
    }

    .project-img-container {
        width: 100%;
        height: auto;
    }

    .project-main-img {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
    }

    .project-item:nth-child(2n) .project-container{
        flex-flow: row-reverse wrap;
    }

    .project-item:nth-child(2n) .project-name  {
        flex-direction: row-reverse;
    }

    .project-item:nth-child(2n) .project-name h3{
        text-align: end;
    }

    .project-item:nth-child(2n) .title-side{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .project-item:nth-child(2n) .links div{
        margin-left: 0rem;
        margin-right: .5rem;
    }

    .project-item:nth-child(2n) .links{
        justify-content: flex-start;
    }
    .modal-content {
        width: 70%;
      }
}
