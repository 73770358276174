* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: hsl(0, 0%, 0%);
  scroll-behavior: smooth;
}

.section-title {
  font-size: 1rem;
  font-family: 'Inconsolata', monospace;
  font-weight: 400;
  padding-bottom: 3rem;
  padding-left: 0;
  padding-right: 0;
  text-transform: uppercase;
}

@media screen and (min-width: 992px) {
  .section-title { 
    padding-bottom: 5rem;
  }
}
