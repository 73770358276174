.header {
    min-height: 100vh;
    width: 100vw;
    padding-top: 10vh;
    color: hsl(0, 0%, 100%);
    background-color: #e3e0de;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly; 
    align-items: flex-start;
    overflow: hidden;
}

.slider-container {
    width: 100%;
    height: 100%;
}

.intro {
    background: transparent;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider1 {
    background-color:  hsl(248, 7%, 56%);
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
}

.slider2 {
    background-color: hsl(248, 48%, 74%);
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
}

.intro {
    background-color: hsl(24, 8%, 88%);
}

.intro-text {
    background: transparent;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
  
.hide {
    background-color: hsl(24, 8%, 88%);
    overflow: hidden;
}
  
.hide span {
    transform: translateY(100%);
    display: inline-block;
}

.header-container {
    width: 100%;
    margin-left: 1.5rem;
}

.header-container h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 3.3rem;
    text-transform: uppercase;
    line-height: .75;
    background-color: transparent;
    margin-left: -.25rem;
}

.name h1:nth-child(2) {
    padding-left: .3rem;
}

.hero-container {
    height: 20vh;
    width: 100%;
    margin: 2rem 0;
    overflow: hidden;
    border: transparent;
}

.hero-container img {
    position: relative;
    top: -112%; 
    filter: grayscale(.3);
    background-image: url("../../images/hero_img_600.webp");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 60vh;
}

.info-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: auto;
    margin-left: 1.5rem;
    position: absolute;
    bottom: 0;
}

.icon{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    padding: 2%;
    margin-bottom: 5%;
    background-color: transparent;
    background-size:contain;
    background-repeat: no-repeat;
}

.cv {
    background-image:url("../../images/icons/cv.svg");
    border-radius: 0%; 
}
.in { background-image: url("../../images/icons/linkedin.svg"); }

.git {
    background-image: url("../../images/icons/github.svg");
    margin-left: 1rem; 
    margin-right: 1rem; 
}

/* @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (orientation: landscape) {
    .hero-container {
        display: none; 
    }
} */

@media only screen and (max-height : 500px)
 {
    .hero-container {
        display: none; 
    }
}

@media screen and (max-width: 375px)  {
    .header-container h1 {
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 400px)  {
    .hero-container img {
        background-image: url("../../images/hero_img.webp");
        top: -145%; 
    }
}

@media screen and (min-width: 992px) {
    .hero-container {
        height: 30vh;
        width: 50vw;
    }
    .hero-container img {
        position: relative;
        top: -55%; 
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .header-container h1 {
       font-size: 6rem;
       margin-left: -.7rem;
    }

    .header {
        height: 100vh;
        width: 100vw;
        padding: 1rem 4.4rem;
        color: white;
        display: flex;
    }

    .header-container {
        width: 100%;
        margin-left: 0rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }

    .name{
        align-self: flex-end;
        display: flex;
        flex-direction: column; 
        align-items: flex-end;
        position: absolute;
        bottom: 0;
    }

    .info-container {
        align-self: flex-start;
        margin: 0;
    }
}
