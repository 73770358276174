.form-container {
    font-family: 'Inconsolata', monospace;
    text-transform: uppercase;
    font-size: 1.25rem;
    width: 100%;
    height: auto;
    overflow: visible;
}

form {
    padding-top: 2rem;
}

.form-container label {
    font-family: 'Inconsolata', monospace;
    position: absolute;
}

.form-container input,
.form-container textarea {
    width: 100%;
    height: 3.5rem;
    min-height: 3.5rem;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #000000;
  
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
}

.form-container textarea {
    overflow: auto;
    resize: vertical;
    padding-top: 1.25rem ;
}

.form-container input[type='submit'] {
    border-bottom: none;
    cursor: pointer;
}

.form-container form div {
    position: relative;
    margin-bottom: 2rem;
    overflow: visible;
}

.form-container form div label {
    position: absolute;
    top: 1.2rem;
    left: 0;
    pointer-events: none;
    text-transform: uppercase;

    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.form-container input:focus ~ label,
.form-container input:valid ~ label {
    top: 0;
    font-size: .7rem;
    color: #8276d1;
}

.form-container textarea:focus ~ label,
.form-container textarea:valid ~ label 
{
    top: -.9rem;
    font-size: .7rem;
    color: #8276d1;
}

.form-container input:focus,
.form-container textarea:focus, 
.form-container input:valid, 
.form-container textarea:valid  {
    border-bottom: 1px solid #8276d1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px hsl(24, 8%, 88%) inset !important;
}

.form-container input#submit {
    display: block;
    margin-right: 0%;
    margin-left: auto;

    font-family: 'Inconsolata', monospace;
    color: #8276d1;
    font-size: 1.25rem;
    text-transform: uppercase;

    width: 5rem; 
    height: 2rem;
    min-height: 2.5rem;
    padding: 0.3rem;

    border: 2px solid #8276d1;
    cursor: pointer;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.email-button {
    display: inline-block;
    position: relative;
    margin: 1em 0;
    padding: 0.67em;
    border-radius: 0.5rem;
    background-color: #aaa4d2;
    outline: none;
    border: none;
    overflow: hidden;
    text-decoration: none;
}

.email-button span {
    font-family: 'Inconsolata', monospace;
    text-transform: uppercase;
    font-size: 1.25rem;
    
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.email-button:before {
    content: 'CLICK TO COPY';
    font-family: 'Inconsolata', monospace;
    text-transform: uppercase;
    font-size: 1.25rem;

    position: absolute;
    width: 100%;
    text-align: center;
    opacity: 0;
    -webkit-transition: .25s,opacity .6s;
    -moz-transition: .25s,opacity .6s;
    -o-transition: .25s,opacity .6s;
    transition: .25s,opacity .6s;
}

.email-button:hover { 
    cursor: grab; 
} 

.email-button:active { 
    cursor: grabbing; 
} 

.email-button:hover span { 
    opacity: 0;
}

.email-button:hover:before {
    opacity: 1;
    -webkit-transition-delay: .25s;
    -moz-transition-delay: .25s;
    -o-transition-delay: .25s;
    transition-delay: .25s;
}

@media screen and (min-width: 992px) {
    .form-container {
        font-family: 'Inconsolata', monospace;
        text-transform: uppercase;
        font-size: 1.25rem;
        width: 42%;
        padding-top: 0rem;
        align-self: center;
        padding-top: 4rem;
        padding-left: 1.63rem;
    }

    
}